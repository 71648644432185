<template>
  <v-sheet class="auth-view">
    <login-dialog ref="loginDialog" />

    <!-- CONNECTED -->
    <v-card class="card connected" v-if="userIsConnected" elevation="0">
      <v-card-title> Hello {{ authUser.displayName }} :) </v-card-title>
      <v-card-text>
        <p>The address of your account is {{ authUser.email }}</p>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="authLogout" color="accent">Disconnect</v-btn>
      </v-card-actions>
    </v-card>

    <!-- DISCONNECTED -->
    <v-card v-else-if="userIsDisconnected">
      <v-card-title> Hello dear visitor :) </v-card-title>
      <v-card-text>
        <p v-if="authRedirect">
          You are trying to access to a page which required to be authenticated.
        </p>
        <p v-else>
          You need to be authenticated to access to all pages of the
          application.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-btn block color="accent" @click="openLogin">Login</v-btn>
        <v-btn block text @click="authGoogleLogin">Login with Google</v-btn>
      </v-card-actions>
    </v-card>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LoginDialog from '../dialog/LoginDialog.vue'

export default {
  name: 'AuthView',
  components: { LoginDialog },
  computed: {
    ...mapGetters('auth', ['authStatus', 'authUser', 'authRedirect']),

    userIsConnected() {
      return this.authStatus === 'connected'
    },

    userIsDisconnected() {
      return this.authStatus === 'disconnected'
    }
  },

  mounted() {},

  methods: {
    ...mapActions('auth', ['authLogout', 'authGoogleLogin']),

    openLogin() {
      this.$refs.loginDialog.open()
    }
  }
}
</script>

<style scoped>
.auth-view {
  /* padding: var(--app-gutter-md); */
  margin: var(--app-gutter-md);
  background: none;
  display: flex;
  align-items: center;
}
.v-card {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  max-width: 350px;
}
.v-card__text {
  padding: 0 var(--app-gutter-md);
}
.v-card__text > *:not(:last-child) {
  margin-bottom: var(--app-gutter-md);
}

.v-card__actions > *:not(:last-child) {
  margin-bottom: var(--app-gutter-md);
}
.v-card__actions {
  padding: var(--app-gutter-md);
  padding-top: 0;
  flex-direction: column;
}
.v-btn {
  margin-left: 0 !important;
}
</style>
